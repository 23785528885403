.App {
  text-align: center;
}

.mailtoLink {
  color: inherit; /* Inherit the text color from the parent */
  text-decoration: none; /* Remove underline */
}

.mailtoLink:hover {
  color: inherit; /* Inherit the text color from the parent */
  text-decoration: underline; /* Add underline on hover */
}

.hero-container{
  position: relative;
}

.header-tornado {
  width: 100%;
  height: 75vh
}

.news-poster {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 60px;
}

#poster-image {
  max-width: 800px;
  width: 100%;
}

#email-contact {
  margin-top: 30px;
  max-width: 400px;
  width: 80%;
  color: #F4F4F4;
  padding: 30px
}

.hero-title {
  width: 100%;
  font-size: 2.5em;
  line-height: 1.5em;
  letter-spacing: .25em;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-subtitle {
  width: 100%;
  font-size: 1.75em;
  line-height: 1.5em;
  letter-spacing: .25em;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page-title {
  font-family: 'Oswald', sans-serif;
  color: #25486C !important;
}

.page-title-white {
  font-family: 'Oswald', sans-serif;
  color: #F4F4F4 !important;
}

.page-title-white-centered {
  font-family: 'Oswald', sans-serif;
  color: #F4F4F4 !important;
  text-align: center;
}

.page-title-red {
  font-family: 'Oswald', sans-serif;
  color: #da1106 !important;
  text-align: center;
}

.page-text {
  font-family: 'Raleway', sans-serif;
  color: #25486C !important;
}

.page-text-white {
  font-family: 'Raleway', sans-serif;
  color: #ffffff !important;
}

.page-text-jus {
  font-family: 'Raleway', sans-serif;
  color: #25486C !important;
  text-align: justify;
}

.section-icon{
  width: 50%;
}

.testimonial-container{
  position: relative;
  margin-top: 15vh;
}

.testimonial-div{
  background: #201E50;
  width: 35%;
  min-height: 75vh;
  color: #ffffff !important;
  display: inline-flex;
  position: relative;
  vertical-align: bottom;
}

.testimonial-img{
  width: 65%;
  height: 75vh;
  display: inline-flex;
  position: relative;
  filter:contrast(85%);
}

.testimonial-background{
  width: 65%;
  height: 75vh;
  display: inline-flex;
  position: absolute;
  top: 0vh;
  left: 35%;
  filter: contrast(85%);
}

.careervideo-container {
  margin-top: 4em;
  margin-bottom: 3em;
}

#gallery-2{
  z-index: -1;
}

#gallery-3{
  z-index: -2;
}

#gallery-4{
  z-index: -3;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.image-cover {
  z-index: 1;
  position: absolute;
  top: 0vh;
  left: 35%;
  width: 0;
  height: 0;
  border-top: 75vh solid #201E50;
  border-right: 30vh solid transparent;
}

.carousel .slide{ background: #201E50; }

.tornadoblue{
  color: #201E50;
}

.tornadowhite{
  color: #F4F4F4;
}

.btn-tornado {
  color: #da1106; 
  background-color: none;
  border: solid 1px #b10c03;
}

.btn-tornado.focus, .btn-tornado:focus {
  color: #da1106; 
  filter:brightness(70%);
}

.btn-tornado:hover {
  color: #25486C;
  border: solid 1px #201E50;
  filter:brightness(85%);
}

.btn-tornado.active, .btn-tornado:active, .open > .dropdown-toggle.btn-tornado {
  color: #da1106;
  filter:brightness(70%);
}

.btn-tornado.active.focus, .btn-tornado.active:focus, .btn-tornado.active:hover, .btn-tornado:active.focus, .btn-tornado:active:focus, .btn-tornado:active:hover, .open > .dropdown-toggle.btn-tornado.focus, .open > .dropdown-toggle.btn-tornado:focus, .open > .dropdown-toggle.btn-tornado:hover {
  color: #da1106;
  filter:brightness(70%);
}

.btn-tornado.active, .btn-tornado:active, .open > .dropdown-toggle.btn-tornado {
  color: #da1106;
  background-image: none;
}

.btn-tornado.disabled.focus, .btn-tornado.disabled:focus, .btn-tornado.disabled:hover, .btn-tornado[disabled].focus, .btn-tornado[disabled]:focus, .btn-tornado[disabled]:hover, fieldset[disabled] .btn-tornado.focus, fieldset[disabled] .btn-tornado:focus, fieldset[disabled] .btn-tornado:hover {
  color: #da1106;
}

.btn-tornado .badge {
  color: #da1106;
}

.control-dots{
  padding: 0px !important;
}

.about-container{
  margin-top: 5em;
  margin-bottom: 5em;
}

.about-section{
  width: 100%;
}

.about-content{
  padding-left: 1em;
  padding-right: 1em;
}

.commitment-row{
  width: 100%;
}

.commitment-list-container{
  width: 100%;
  padding-left: 20%;
  padding-right: 20%
}

.commitment-list{
  margin-bottom: 6em;
  font-size: 1.2rem;
}

.commitment{
  margin-bottom: 1em;
}

.sim-container {
  margin-top: 6em;
  margin-bottom: 6em;
  padding-top: 2em;
}

.sim-text{
  font-size: 1.2rem;
}

.emersim-img{
  width:100%
}

.ditching-video-container{
  margin-top: 2em;
  margin-left: 20%;
  margin-right: 20%;
  width: 60%;
  height: 330px;
}

.careers-container{
  margin-top: 3em;
  margin-bottom: 3em
}

.course-container{
  margin-top: 3em;
}

.course-card.left{
  height: 35vh;
  width: 90%;
  margin-left: 10%;
  background: #201E50;
  position: relative;
}

.course-card.right{
  height: 35vh;
  width: 90%;
  margin-right: 10%;
  background: #201E50;
  position: relative;
}

.course-list-card{
  height: 100%;
  width: 100%;
  background: #201E50;
  position: relative;
}

.course-image-div{
  display: inline-block;
  width: 45%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.course-text-div{
  vertical-align: top;
  display: inline-block;
  width: 55%;
  height: 100%;
}

.course-text{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.6rem;
}

.course-card-img{
  width: 90%;
  border-radius: 50%;
  margin-left: 15%;
}

.course-list-title{
  width: 100%;
  display: inline-block
}

.contact-arrow{
  width: 100%;
  font-size: 1.5rem;
}

.drone-img{
  width: 90%;
  clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);
  margin-right: 5%;
  margin-left: 5%
}

.drone-title{
  text-align: center
}

.drone-text{
  padding: 0px 2em;
}

.drone-video-container{
  margin-top: 2em;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  height: 330px;
}

.services-container{
  margin-bottom: 4em;
}

.sms-services-title{
  margin-top: 3em;
  margin-bottom: 2em;
}

.sms-services-text{
  font-size: 1.3rem;
  padding: 0em 1em 0em 1em;
}

.sms-description-text{
  font-size: 1rem;
  padding: 0em 1em 0em 1em;
}

.text-div{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.sms-card {
  height: 30vh;
  width: 90%;
  margin: 0em 5% 3em 5%;
  background: #201E50;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.description-overlay {
  position: absolute;
  height: 30vh;
  width: 100%;

  bottom: 0;
  left: 100%;
  right: 0;
  background-color: #201E50;
  overflow: hidden;
  transition: .5s ease;

  text-align: left;
}

.sms-card:hover .description-overlay, .sms-card:active .description-overlay {
  width: 100%;
  left: 0;
}

.contact-title{
  margin-top: 2em;
}

.contact-wrapper{
  margin-top: 3em;
  margin-bottom: 5em;
}

@media (min-width: 767px) { 
  .btn-mobile {
    display: none;
  }
}

@media (max-width: 992px) {

  .hero-title {
    font-size: 2.25em;
  }
  
  .hero-subtitle {
    font-size: 1.5em;
  }

  .emersim-img{
    display: none;
  }
  .ditching-video-container{
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    height: 50vh;
  }
  .drone-video-container{
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    height: 50vh;
  }
  .about-container{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%
  }
  .drone-text{
    padding-right: 0em;
  }
  .course-text{
    font-size: 1.4rem;
  }
  .course-card.left{
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  .course-card.right{
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  .careers-container{
    margin-top: 1em;
    margin-bottom: 2em
  }
}

@media (max-width: 767px) { 

  .hero-title {
    top: 42%;
    font-size: 1.5em;
  }
  
  .hero-subtitle {
    top: 58%;
    font-size: 1.25em;
  }

  .desktop-buttons {
    display: none;
  }
  .btn-mobile {
    margin-bottom: 10vh;
  }
  .testimonial-container{
    margin-top: 0px;
  }
  .testimonial-div{
    width: 100%;
    min-height: auto;
    height: auto;
  }
  .my-slide{
    margin-bottom: 3em;
  }
  .image-cover, .testimonial-img, .testimonial-background {
    display: none;
  }
  .header-tornado {
    width: 100%;
    height: 35vh;
  }
  .sms-card {
    width: 90%;
    margin: 0em 5% 1em 5%;
  }
  .services-container{
    margin-bottom: 4em;
  }
}

@media (max-width: 576px) {

  .hero-title {
    width: 80%;
    top: 45%;
    font-size: 1.75em;
  }
  
  .hero-subtitle {
    top: 70%;
    font-size: 1em;
  }

  .careervideo-container {
    margin-top: 2em;
  }

  .ditching-video-container{
    margin-top: 1em;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    height: 35vh;
  }
  .drone-video-container{
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
    height: 35vh;
  }
  .commitment-list-container{
    padding-left: 10%;
    padding-right: 10%
  }
  .commitment-list{
    margin-bottom: 3em;
  }
  .sim-container {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .about-container{
    margin-top: 3em;
    margin-bottom: 3em;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%
  }
  .about-content{
    padding-left: 0em;
    padding-right: 0em;
    font-size: 1.2rem;
  }
  .mision-text, .carreras-text{
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
  .contact-wrapper{
    margin-bottom: 0em;
  }
  .my-slide{
    padding-bottom: 1em !important;
  }

  #email-contact {
    margin-top: 0px;
    max-width: initial;
    width: 100%;
    padding: 20px;
    font-size: .9em;
  }
}

@media (max-width: 400px) {

  .hero-title {
    width: 80%;
    top: 45%;
    font-size: 1.5em;
  }
  
  .hero-subtitle {
    top: 70%;
    font-size: .75em;
  }

}