.contact-container{
  display: flex;
  justify-content: center; 
  align-items: stretch; 
}

.card-row{
  width: 100%;
}

.contact-content{
  background: #201E50;
}

.map-wrapper{
  width: 85%;
  height: 100%;
}

@media (max-width: 992px){
  .map-wrapper{
    width: 100%;
    height: 40vh;
  }
  .map-column{
    padding-left: 0%;
    padding-right: 0%;
  }
  .contact-icon-col{
    display: none;
  }
}